<template>
  <div>
    <Loading v-if="$global.state.loading" />
    <div v-if="!$global.state.loading">
      <v-card class="transparent" outlined>
        <v-card-title>
          <span class="font-weight-bold">
            {{ $route.name }}
          </span>
          <v-spacer></v-spacer>
          <v-btn color="primary" to="/emails/add" large>
            <h3>
              <v-icon class="ml-2">email</v-icon>
              {{ $t('inshaa-rsalh') }}
            </h3>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-tabs class="my-5" v-model="type">
            <v-tab>
              <v-icon>mdi-email</v-icon>
              {{ $t('alrsael-alwardh') }}
            </v-tab>
            <v-tab>
              <v-icon>mdi-email-outline</v-icon>
              {{ $t('alrsael-almrslh') }}
            </v-tab>
            <v-tab>
              <v-icon>mdi-email-alert-outline</v-icon>
              {{ $t('alrsael-almurshfh') }}
            </v-tab>
          </v-tabs>

          <empty v-if="items == ''" />
          <v-expansion-panels flat v-if="items != ''" multiple>
            <v-expansion-panel
              v-for="(item, index) in items"
              :key="index"
              id="card"
            >
              <v-expansion-panel-header>
                <v-row justify="space-around" no-gutters>
                  <v-divider
                    vertical
                    :class="item.isRead == false ? 'primary ml-2' : 'grey ml-2'"
                    style="border: solid 3px; border-radius: 30px"
                  />
                  <v-col cols="1">
                    <v-avatar size="60" rounded>
                      <v-img
                        :src="
                          item.sender.imageUrl
                            ? $config.BASE_URL_PROD + '/' + item.sender.imageUrl
                            : $store.state.defaultPhoto
                        "
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="3">
                    <h3 class="mt-2">
                      {{ $t('almrsl') }}:
                      {{ item.sender.fullName }}
                      <h5 class="grey--text mt-3">
                        ( {{ item.sender.jobTitle }} )
                      </h5>
                    </h3>
                    <h3 class="mt-2">
                      {{ $t('almstlmyn') }}:
                      <v-chip-group>
                        <v-chip
                          v-for="receiver in item.receivers"
                          :key="receiver.id"
                          :color="'primary'"
                        >
                          {{ receiver.receiver.fullName }}
                        </v-chip>
                      </v-chip-group>
                    </h3>
                  </v-col>
                  <v-divider vertical class="mx-4" />
                  <v-col cols="4">
                    <h3 class="mt-2">
                      {{ item.title }}
                      <h5 class="grey--text mt-3" v-html="item.body"></h5>
                    </h3>
                  </v-col>
                  <v-spacer />
                  <v-col cols="2">
                    <h4
                      style="color: #122940"
                      dir="ltr"
                      class="text-start mx-2"
                    >
                      <h5 class="grey--text">
                        {{
                          new Date(item.created).toISOString().split("T")[0]
                        }},<span>{{
                          new Date(item.created).toLocaleString().split(",")[1]
                        }}</span>
                      </h5>
                      <br />
                      <span
                        >{{ item.attachments.length }}
                        <v-icon small>attach_file</v-icon>
                      </span>
                      <!-- <span>item.isArchive
                                            <v-icon small>archive</v-icon>
                                        </span> -->
                      <v-btn
                        icon
                        class="ml-8"
                        @click.stop="archive(item)"
                        :color="item.isArchive ? 'primary' : ''"
                      >
                        <v-icon>{{
                          item.isArchive ? "unarchive" : "archive"
                        }}</v-icon>
                      </v-btn>
                    </h4>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-divider />
              <v-expansion-panel-content>
                <v-card outlined tile class="mt-4">
                  <v-container fluid>
                    <h3 v-html="item.body" />
                  </v-container>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      @click="
                        fileExt(attachment.path) == 'jpg' ||
                        fileExt(attachment.path) == 'png' ||
                        fileExt(attachment.path) == 'jpeg' ||
                        fileExt(attachment.path) == 'gif' ||
                        fileExt(attachment.path) == 'fav' ||
                        fileExt(attachment.path) == 'ico' ||
                        fileExt(attachment.path) == 'pdf'
                          ? view(attachment.path)
                          : downloadURI(
                              $config.BASE_URL_PROD + '/' + attachment.path,
                              item.title
                            )
                      "
                      class="mr-2"
                      color="teal"
                      outlined
                      cols="12"
                      md="3"
                      v-for="(attachment, i) in item.attachments"
                      :key="i"
                    >
                      <h3>
                        {{
                          attachment.name
                            .split("/")
                            .pop()
                            .replace(
                              /(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/,
                              ""
                            )
                        }}
                      </h3>
                      <v-spacer />
                      <v-icon class="mr-2">cloud_download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
      <Pagination />
    </div>
  </div>
</template>

<script>
import Loading from "../../components/Loading.vue";
export default {
  components: {
    Loading,
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    return {
      items: [],
      panel: [],
      type: "",
      options: {
        ReceiverId: this.$store.state.user.id,
        IsDeleted: false,
        IsArchive: false,
        PageIndex: this.$global.state.filter.pageIndex,
        PageSize: this.$global.state.filter.pageSize,
        ArchiveUserId:"",
        SenderId: "",
        Sort: "desc",
      },
    };
  },
  watch: {
    type(val) {
      this.options.SenderId = val == 1 ? this.$store.state.user.id : "";
      this.options.ReceiverId =
        val == 0 ? this.$store.state.user.id : "";
      this.options.IsArchive = val == 2;
      this.options.ArchiveUserId = val == 2 ? this.$store.state.user.id : "";
      this.getItems();
    },
  },
  methods: {
    async archive(item) {
      this.$store.dispatch("loading", true);
      await this.$http.put("/email/archive/" + item.id);
      this.$store.dispatch("loading", false);
      this.$service.success(this.$t('tm-altadyl-bnjah'));
      this.getItems();
    },
    view(file) {
      window.open(this.$config.BASE_URL_PROD + "/" + file, "_blank");
    },
    fileExt(file) {
      return file.split(".").pop().toLowerCase();
    },
    async getItems() {
      this.$global.state.loading = true;
      try {
        const res = await this.$http.get(`Email`, {
          params: this.options,
        });
        this.items = res.data.result;
        this.$global.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
      } catch (err) {
        console.log(err);
      } finally {
        this.$global.state.loading = false;
      }
    },

    deleteItem(id, i) {
      this.$service.deleteItem(id, `/${this.$route.meta.endPoint}`, `delete`);
      this.$eventBus.$once(`delete`, () => {
        setTimeout(() => {
          this.getItems();
        }, 10);
      });
    },

    downloadURI(url, filename) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(console.error);
    },

    openAddDialog() {
      var item = {
        dialogType: "add",
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
  mounted() {
    this.getItems();
    this.$eventBus.$on(`refresh`, () => {
      this.getItems();
    });
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
    this.$eventBus.$off(`delete`);
    this.$eventBus.$off(`active`);
  },
};
</script>
